import { deleteCookie, getCookie } from "cookies-next";
import axios, { AxiosError } from "axios";
import { useIsAuthenticated } from "@/hooks/utils";

const switchToHTTPS = (url: string) => url.replace(/^http:/, "https:");

const getAuthToken = () => getCookie("ecn.client.auth.token");
export const authAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});

authAxios.interceptors.request.use((config) => {
  const token = getCookie("ecn.client.auth.token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

authAxios.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    const { response } = error;

    if (response?.status === 401) {
      const isAuthenticated = useIsAuthenticated();

      if (isAuthenticated) {
        deleteCookie("ecn.client.auth.token");

        localStorage.removeItem("ecn.user.permissions");
        localStorage.removeItem("ecn.client.authenticated");
        localStorage.removeItem("ecn.user.bio");

        sessionStorage.clear();
      }

      window.location.href = "/";
    }

    return Promise.reject(response);
  }
);

export async function authGetter(url: string) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .get(switchToHTTPS(url))
    .then((response) => response);
  return response?.data;
}

export async function authPoster(url: string, data) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .post(switchToHTTPS(url), data)
    .then((response) => response);
  return response?.data;
}

export async function authDeleter(url: string, data = {}) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .delete(switchToHTTPS(url), data)
    .then((response) => response);
  return response?.data;
}

export async function authPutter(url: string, data) {
  authAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${getAuthToken()}`;
  const response = await authAxios
    .put(switchToHTTPS(url), data)
    .then((response) => response);
  return response?.data;
}
