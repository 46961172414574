import { useRouter } from "next/router";
import { PermissionType, usePermissions } from "./usePermissions";
import { useIsAuthenticated } from "./useIsAuthenticated";
import { useMemo } from "react";

type FallbackPath = "/404" | "/" | (string & {});

export function useCanAccess<Permissions extends PermissionType>(
  ...permissionList: Permissions[]
) {
  const { replace, push } = useRouter();
  const { checkPermission, permissionsFetching } = usePermissions();

  const isAuthenticated = useIsAuthenticated();
  const hasAtLeastOnePermission = useMemo(
    () => permissionList.some(checkPermission),
    [permissionsFetching]
  );
  const hasAllRequiredPermissions = useMemo(
    () => permissionList.every(checkPermission),
    [permissionsFetching]
  );

  const permissions = useMemo(
    () =>
      permissionList.reduce((acc, permission) => {
        acc[permission] = checkPermission(permission);
        return acc;
      }, {} as Record<Permissions, boolean>),
    [permissionsFetching]
  );

  return {
    permissions,
    hasAllRequiredPermissions,
    hasAtLeastOnePermission,
    redirectTo: function <Path extends FallbackPath = "/">(
      fallbackPath: Path = "/" as Path
    ) {
      if (!hasAllRequiredPermissions) {
        if (isAuthenticated) replace(fallbackPath);
        else push("/");
      }
      return this;
    },
  };
}
